<!-- 引导前往 App 的顶部栏 -->
<template>
	<div :class="['container', lightTheme ? 'lightTheme' : '']">
		<img
			src="../assets/images/icon_cumenlogo.png"
			alt="logo"
			class="logo"
		/>
		<div class="info">
			<span>粗门</span>
			<span>粗门，一起玩！</span>
		</div>
		<div class="open-app" @click="showModalHandler">打开App</div>
	</div>
</template>

<script>
export default {
	name: "TopGuide",
	components: {
	},
	props: {

	},
	data() {
		return {
			lightTheme: false,
		};
	},
	mounted() {
		this.bindPageScroll();
	},
	methods: {
		/**
		 * 监听页面上下滚动
		 */
		bindPageScroll() {
			window.onscroll = (e) => {
				const scrollTop =
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.lightTheme = scrollTop > 140;
			};
		},
		/**
		 * 显示询问模态框
		 */
		showModalHandler() {
			this.$emit("showModalHandler");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.container {
	@include row(space-between, center);
	@include border-box();
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	padding: px2rem(24) px2rem(32);
	background-color: #1f1f1f;
	box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
	z-index: 999;
	transition: all 0.3s linear;
	.logo {
		width: px2rem(96);
		height: px2rem(96);
	}
	.info {
		flex: 1;
		margin-left: px2rem(22);
		> span {
			@include column();
			color: #fff;
			&:first-child {
				height: px2rem(48);
				line-height: px2rem(48);
				font-size: px2rem(32);
				font-family: $Alibaba-PuHuiTi-H;
			}
			&:nth-child(2) {
				height: px2rem(40);
				font-size: px2rem(28);
				font-family: PingFangSC-Regular, PingFang SC;
				line-height: px2rem(40);
				margin-top: px2rem(4);
			}
		}
	}
	.open-app {
		width: px2rem(160);
		height: px2rem(64);
		line-height: px2rem(64);
		text-align: center;
		font-size: px2rem(28);
		font-family: $Alibaba-PuHuiTi-H;
		color: $theme-color-black;
		background-color: $theme-color-green;
		border-radius: px2rem(32);
	}
}
.lightTheme {
	background-color: #fff;
	> .info > span {
		color: #333;
	}
}
</style>
