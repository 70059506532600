import avatarImg from "../assets/images/avatar.jpg";

const campaignInfo = {
	campaign: {
		id: "",
		kind: 1,
		participant_avatar_urls: [avatarImg, avatarImg, avatarImg],
		participation_prompt: "",
		title: ""
	},
	post: {
		author: {
			avatar_url: avatarImg,
			birthday: null,
			contact_qr_code_url: "",
			gender: "GENDER_FEMALE",
			has_cellphone: true,
			id: "",
			im_id: "",
			nickname: "",
			sign: "",
			status: "",
		},
		cellphone: "",
		contact_qr_code_url: "",
		content: "",
		create_time: "2022-01-17T10:31:29.402506Z",
		id: "",
		images: [
			{ url: "", _cover: "" },
		],
		poi: {
			address: "",
			id: "",
			location_gcj02: {
				latitude: 30.540207,
				longitude: 104.059541,
			},
			name: "",
		},
		status: "POST_STATUS_UNSPECIFIED",
		update_time: "2022-01-12T10:31:29.404979Z",
	},
	teams: [],
	teams_title: "",
	threads: []
}

export { campaignInfo };