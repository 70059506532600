<template>
	<div class="comment-item">
		<img
			:src="info.author.avatar_url"
			alt="avatar"
			:class="{ avatar: true, 'small-size': child }"
		/>
		<div class="main">
			<div class="header">
				<span class="nickname">嘀哩嘀哩</span>
				<img
					:src="
						info.author.gender === 'GENDER_MALE'
							? genderBoy
							: genderGirl
					"
					alt="gender"
					class="gender"
				/>
				<div class="relation" v-if="false">
					<span>熟人</span>
				</div>
				<span class="date">· {{info.create_time.substring(5,10)}}</span>
			</div>
			<div class="content">{{ info.content }}</div>
			<div class="photo-box" v-if="info.object_info">
				<img
					:src="info.object_info.url"
					alt="photo"
					class="photo"
				/>
			</div>
			<slot />
		</div>
	</div>
</template>

<script>
import genderBoy from "@/assets/images/tag_boy.png";
import genderGirl from "@/assets/images/tag_girl.png";
export default {
	name: "Comment",
	props: {
		child: {
			type: Boolean,
			value: false,
		},
		info: {
			type: Object,
			value: {},
		},
	},
	data() {
		return {
			genderBoy: genderBoy,
			genderGirl: genderGirl,
		};
	},
	methods: {
		
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.comment-item {
	@include row(flex-start, flex-start);
	width: 100%;
	padding: px2rem(32) 0;
	.avatar {
		width: px2rem(64);
		height: px2rem(64);
		border-radius: 50%;
	}
	.small-size {
		width: px2rem(40);
		height: px2rem(40);
	}
	.main {
		@include column();
		margin-left: px2rem(16);
		.header {
			@include row(flex-start, center);
			width: 100%;
			height: px2rem(40);
			.nickname {
				display: block;
				color: #a3a3a3;
				font-size: px2rem(28);
				line-height: px2rem(28);
				padding-top: px2rem(6);
				font-weight: bold;
			}
			.gender {
				width: px2rem(32);
				height: px2rem(32);
				margin-left: px2rem(8);
			}
			.relation {
				width: px2rem(56);
				height: px2rem(32);
				line-height: px2rem(32);
				text-align: center;
				margin-left: px2rem(8);
				background-color: #f6f6f6;
				border-radius: px2rem(6);
				span {
					display: block;
					color: #a3a3a3;
					font-size: px2rem(24);
					padding-left: px2rem(4);
					transform: scale(0.83);
					font-family: $Alibaba-PuHuiTi-H;
				}
			}
			.date {
				color: #a3a3a3;
				font-size: px2rem(20);
				margin-left: px2rem(8);
			}
		}
		.content {
			color: #222;
			font-size: px2rem(28);
			line-height: px2rem(48);
			margin-top: px2rem(12);
			text-align: justify;
			font-family: $PingFangSC-Regular;
		}
		.photo-box {
			@include row(space-between, center);
			margin-top: px2rem(24);
			.photo {
				width: px2rem(168);
				height: px2rem(168);
				object-fit: cover;
				border-radius: px2rem(12);
			}
		}
	}
}
</style>
