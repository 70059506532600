<template>
	<div class="activity" v-show="showPage">
		<top-guide @showModalHandler="showModalHandler" />
		<!-- 头图 -->
		<div
			class="banner"
			:style="
				'background-image: url(' +
				campaignInfo.post.images[0]._cover +
				')'
			"
		>
			<div class="shadow"></div>
			<!-- 活动标语 -->
			<div class="slogan" v-if="campaignId">
				<div class="icon"></div>
				<div class="title">
					<span
						v-for="item of campaignInfo.campaign.title"
						:key="1 + item"
						>{{ item }}</span
					>
				</div>
				<div class="title shadow-box">
					<span
						v-for="item of campaignInfo.campaign.title"
						:key="2 + item"
						>{{ item }}</span
					>
				</div>
			</div>
			<!-- 地址 -->
			<div class="poi" v-if="campaignId">
				<img
					src="../assets/images/white_poi.png"
					alt="poi"
					class="icon"
				/>
				<div class="address">{{ campaignInfo.post.poi.name }}</div>
			</div>
		</div>
		<!-- 动态内容 -->
		<div class="post">
			<div class="details" v-if="campaignId">
				<div class="header">
					<img
						:src="campaignInfo.post.author.avatar_url"
						alt="avatar"
						class="avatar"
					/>
					<span class="nickname">{{
						campaignInfo.post.author.nickname
					}}</span>
					<span class="label">
						<span>发起人</span>
					</span>
					<img
						v-if="campaignInfo.post.author.gender"
						:src="
							campaignInfo.post.author.gender === 'GENDER_MALE'
								? genderBoy
								: genderGirl
						"
						alt="gender"
						class="gender"
					/>
					<div class="relation" v-if="false">
						<span>熟人</span>
					</div>
					<span class="date"
						>·
						{{
							campaignInfo.post.create_time.substring(5, 10)
						}}</span
					>
				</div>
				<div class="content">{{ campaignInfo.post.content }}</div>
				<div class="photo-box">
					<template
						v-for="p in campaignInfo.post.images"
						:key="p.url"
					>
						<template v-if="!p._fileType">
							<img :src="p.url" alt="photo" class="photo" />
						</template>
						<template v-else>
							<video controls :poster="p._cover" class="photo video">
								<source :src="p.url" type="video/mp4" />
							</video>
						</template>
					</template>
					<div
						class="placeholader-area"
						v-if="campaignInfo.post.images.length === 2"
					></div>
				</div>
			</div>
		</div>
		<div class="hr"></div>
		<!-- 车队 -->
		<div v-if="campaignInfo.teams.length" class="motorcade">
			<div class="header">
				<span>{{ campaignInfo.teams_title }}</span>
				<span v-if="campaignInfo.teams[0].vacancy_count">
					已上车{{
						campaignInfo.teams[0].participant_count
					}}人，还剩{{ campaignInfo.teams[0].vacancy_count }}人
				</span>
				<span v-else>已满座</span>
			</div>
			<div class="avatar-group">
				<div
					class="avatar-group-item"
					v-for="item of campaignInfo.teams[0].users"
					:key="item.id"
					data-uid="{{item.id}}"
				>
					<img class="avatar" :src="item.avatar_url" />
					<span class="nickname">{{ item.nickname }}</span>
				</div>
			</div>
		</div>
		<div class="hr"></div>
		<!-- 活动详情 -->
		<div class="details-box">
			<span class="title">活动详情</span>
			<div class="content"></div>
		</div>
		<!-- 引导前往 App 的按钮 -->
		<div class="open-app" @click="showModalHandler">粗门App内打开</div>
		<!-- 弹框 -->
		<toast v-show="showToast" title="参数错误" />
		<!-- 询问模态框 -->
		<modal
			:campaignId="campaignId"
			:content="'即将离开' + platform[platformIdx] + '打开“粗门app”'"
			v-model:showModal="showModal"
		/>
		<!-- 引导默认浏览器打开网页 -->
		<guide-app v-model:showGuide="showGuide" />
	</div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
import TopGuide from "@/components/TopGuide.vue";
import Toast from "@/components/Toast.vue";
import Modal from "@/components/Modal.vue";
import Comment from "@/components/Comment.vue";
import { campaignInfo } from "@/utils/mock.js";
import genderBoy from "@/assets/images/tag_boy.png";
import genderGirl from "@/assets/images/tag_girl.png";
import GuideApp from "@/components/GuideApp.vue";

export default {
	name: "Activity",
	components: {
		TopGuide,
		Comment,
		Toast,
		Modal,
		GuideApp,
	},
	data() {
		return {
			showPage: false,
			showToast: false,
			showModal: false,
			showGuide: false,
			campaignId: "",
			teamId: "",
			campaignInfo: campaignInfo,
			genderBoy: genderBoy,
			genderGirl: genderGirl,
			platform: ["微信", "浏览器"],
			platformIdx: 0,
			firstImgWidth: 0,
			firstImgHeight: 0,
		};
	},
	created() {
		/* 是否在微信内置浏览器环境中 */
		this.isWechatBrowser =
			window.navigator.userAgent
				.toLowerCase()
				.indexOf("micromessenger") !== -1;
		this.showGuide = this.isWechatBrowser;
		this.platformIdx = this.isWechatBrowser ? 0 : 1;
		// 获取活动详情
		this.initParams();
	},
	methods: {
		/**
		 * 初始化参数
		 */
		initParams() {
			let { campaignId, teamId } = this.$route.query;
			if (campaignId) {
				this.campaignId = campaignId;
				this.teamId = teamId;
				this.getCampaign();
			} else {
				this.showPage = true;
				this.showToast = true;
				setTimeout(() => {
					this.showToast = false;
				}, 3e3);
			}
		},
		/**
		 * 获取活动详情
		 */
		getCampaign() {
			let { campaignId, teamId } = this;
			$fetch
				.post("GetSharedCampaign", {
					postId: "",
					campaignId,
				})
				.then((res) => {
					// 格式化动态的文件内容
					res.post.images.forEach((file, index) => {
						if (file.content_type.includes("image")) {
							// 以第一张图片的尺寸作为容器尺寸
							if (!index) {
								this.firstImgWidth = 1;
								this.firstImgHeight = 1;
							}
							file._fileType = 0;
							file._cover = file.image_info.thumbnail_url;
						} else {
							file._fileType = 1;
							file._cover = file.video_info.thumbnail;
						}
					});
					// 分享人所在车队置顶
					for (let i = res.teams.length - 1; i >= 0; i--) {
						if (res.teams[i].id === teamId) {
							const team = res.teams[i];
							res.teams.splice(i, 1);
							res.teams.unshift(team);
						}
					}
					// 动态设置页面标题
					document.title = `粗门 - ${res.campaign.title}`;
					this.campaignInfo = res;
					this.showPage = true;
				});
		},
		/**
		 * 显示询问模态框
		 */
		showModalHandler() {
			if (this.isWechatBrowser) this.showGuide = true;
			else this.showModal = true;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.activity {
	overflow-x: hidden;
}
.banner {
	position: relative;
	width: 100%;
	height: px2rem(600);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-color: #000;
	overflow: hidden;
	.shadow {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
	}
	.slogan,
	.poi {
		@include row(flex-start, center);
		@include border-box();
		position: relative;
		width: 100%;
		padding: 0 px2rem(26);
	}
	.slogan {
		position: relative;
		height: px2rem(80);
		margin-top: px2rem(262);
		margin-left: px2rem(-6);

		.icon {
			width: px2rem(152);
			height: px2rem(84);
			background: url("../assets/images/group.png") no-repeat center
				center / cover;
		}

		.title {
			@include row(flex-start, center);
			position: relative;
			flex: 1;
			transform-origin: left bottom;
			transform: skewX(-10deg) scaleX(1) skewY(-1deg);
			margin-left: px2rem(6);
			margin-top: px2rem(2);
			z-index: 2;

			span {
				color: #fff;
				font-size: px2rem(30);
				font-weight: bold;
				&:last-of-type {
					&::after {
						content: "";
						position: absolute;
						right: px2rem(-32);
						top: px2rem(-6);
						width: px2rem(28);
						height: px2rem(20);
						background: url("../assets/images/quotation-marks-white.png")
							no-repeat center center / contain;
					}
				}
			}

			@for $i from 0 through 20 {
				span:nth-of-type(#{$i}) {
					margin-left: 1 + $i * 0.05px;
					transform: scaleY(0.95 + $i * 0.05);
				}
			}

			.symbol {
				position: relative;
				top: px2rem(-20);
				width: px2rem(35);
				height: px2rem(22);
			}
		}
		.shadow-box {
			position: absolute;
			left: px2rem(196);
			z-index: 0;
			> span {
				color: #f00;
				-webkit-text-stroke: 4px #222;
				&:last-of-type {
					&::after {
						content: "";
						opacity: 0;
					}
				}
			}
		}
	}
	.poi {
		padding-left: px2rem(36);
		margin-top: px2rem(2);
		height: px2rem(64);
		.icon {
			width: px2rem(30);
			height: px2rem(37);
			object-fit: cover;
		}
		.address {
			color: #fff;
			font-size: px2rem(28);
			font-weight: bold;
			margin-left: px2rem(14);
		}
	}
}
.post {
	@include row();
	@include border-box();
	margin-top: px2rem(-112);
	position: relative;
	width: 100%;
	padding: 0 px2rem(32);
	background-color: #fff;
	z-index: 2;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: px2rem(-50);
		width: 100%;
		height: px2rem(100);
		transform: rotate(-2deg);
		border-radius: px2rem(32);
		background-color: #fff;
		z-index: -1;
	}
	.details {
		flex: 1;
		.header {
			@include row(flex-start, center);
			width: 100%;
			.avatar {
				width: px2rem(64);
				height: px2rem(64);
				border-radius: 50%;
			}
			.nickname {
				display: block;
				color: #333;
				font-size: px2rem(28);
				line-height: px2rem(32);
				font-weight: bold;
				margin-left: px2rem(16);
				font-family: $Alibaba-PuHuiTi-H;
			}
			.label {
				width: px2rem(76);
				height: px2rem(32);
				line-height: px2rem(32);
				color: $theme-color-green;
				font-size: px2rem(24);
				text-align: center;
				background-color: $theme-color-black;
				border-radius: px2rem(6);
				margin-left: px2rem(10);
				span {
					display: block;
					transform: scale(0.83);
					font-family: "AlibabaPuHuiTiH";
				}
			}
			.gender {
				width: px2rem(32);
				height: px2rem(32);
				margin-left: px2rem(8);
			}
			.relation {
				width: px2rem(56);
				height: px2rem(32);
				line-height: px2rem(32);
				text-align: center;
				margin-left: px2rem(8);
				background-color: #f6f6f6;
				border-radius: px2rem(6);
				span {
					display: block;
					color: #a3a3a3;
					font-size: px2rem(24);
					padding-left: px2rem(4);
					transform: scale(0.83);
					font-family: $Alibaba-PuHuiTi-H;
				}
			}
			.date {
				color: #a3a3a3;
				font-size: px2rem(20);
				margin-left: px2rem(8);
			}
		}
		.content {
			font-size: px2rem(30);
			line-height: px2rem(56);
			margin-top: px2rem(12);
			text-align: justify;
			margin-top: px2rem(24);
			font-weight: 400;
			color: #333;
			font-family: $PingFangSC-Regular;
		}
		.photo-box {
			@include row(space-between, center);
			margin: px2rem(24) 0 px2rem(32);
			.photo {
				width: calc((100vw - px2rem(80)) / 3);
				height: calc((100vw - px2rem(80)) / 3);
				object-fit: cover;
				border-radius: px2rem(12);
			}
			.video {
				width: px2rem(304);
				height: px2rem(304);
			}
			.placeholader-area {
				width: calc((100vw - px2rem(166)) / 3);
			}
		}
	}
}
.hr {
	width: 100%;
	height: px2rem(16);
	background-color: #f7f7f7;
}
.motorcade {
	.header {
		@include row(space-between, flex-start);
		height: px2rem(96);
		line-height: px2rem(100);
		color: #a3a3a3;
		font-size: px2rem(28);
		font-family: $PingFangSC-Regular;
		font-weight: 500;
		padding: 0 px2rem(32);

		span:nth-child(2),
		span:nth-child(3) {
			height: px2rem(32);
			font-size: px2rem(24);
			font-family: $PingFangSC-Regular;
			font-weight: 500;
			color: #00ca94;
			line-height: px2rem(32);
			padding-top: px2rem(36);
		}
	}
	.avatar-group {
		@include row(flex-start, center);
		flex-wrap: wrap;
		padding: 0 px2rem(22);

		.avatar-group-item {
			@include column(flex-start, center);
			width: calc(100% / 6);
			height: px2rem(162);

			.avatar {
				width: px2rem(80);
				height: px2rem(80);
				margin-top: px2rem(16);
				border-radius: 50%;
			}

			.nickname {
				width: px2rem(96);
				height: px2rem(34);
				font-size: px2rem(24);
				font-family: $PingFangSC-Regular;
				color: #a3a3a3;
				margin-top: px2rem(8);
				margin-bottom: px2rem(16);
				line-height: px2rem(34);
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}
}
.details-box {
	> .title {
		height: px2rem(96);
		line-height: px2rem(96);
		font-size: px2rem(28);
		font-family: $PingFangSC-Regular;
		font-weight: 500;
		color: #a3a3a3;
		padding-left: px2rem(32);
		background-color: #fff;
	}

	> .content {
		width: 100%;
		height: 30vh;
		min-height: 30vh;
	}
}
.open-app {
	position: fixed;
	left: 50%;
	bottom: px2rem(114);
	width: px2rem(274);
	height: px2rem(80);
	line-height: px2rem(80);
	text-align: center;
	font-size: px2rem(30);
	border-radius: px2rem(40);
	color: $theme-color-black;
	background-color: $theme-color-green;
	font-family: $Alibaba-PuHuiTi-H;
	transform: translateX(-50%);
}
</style>
